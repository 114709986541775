import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class ApplicationSettingService {
  private apiPrefix = '/public/application-settings'

  constructor(
    private api: ApiService
  ) {
  }

  getSettings() {
    return this.api.get(`${this.apiPrefix}`);
  }

  getStaticSettings() {
    return this.api.getFromUrl(`https://grikoc-cdn.s3.eu-west-1.amazonaws.com/site-static/settings.json`);
  }
}
